h1, h2 {
    text-align: center;
}

.connecting {
    text-align: center;
    margin: 7%;
}

.connectingProgress {
    margin-top: 5%;
}

.avatar {
    margin: auto;
    background-color: green !important;
}

.login {
    margin-top: 7%;
}

.usersActual {
    margin-top: 7% !important;
}

.profileTable td {
    padding: 0px 20px;
}

.MuiTableContainer-root {
    margin: auto;
    max-width: 1000px !important;
}

.tableButton {
    width: 130px;
}

.dialogButton {
    margin: 5px !important;
    width: 160px;
}

.groupButtons {
    float: right;
}

.roleSelect {
    width: 100%;
}

.fab {
    right: 30px;
    bottom: 30px;
    position: fixed !important;
}

.MuiDialog-paper {
    max-width: 1000px !important;
}

.MuiDialog-paperWidthSm {
    width: 800px;
    max-width: 800px !important;
}

.MuiDropzoneArea-textContainer {
    margin: 20px;
    padding: 20px;
}

.MuiAccordion-root {
    margin: 16px 0;
}

.testForm {
    margin: auto;
    max-width: 1000px;
}

.testFormQuestion {
    margin-top: 20px;
    padding: 15px;
}

.questionImage {
    width: 100%;
    height: 100%;
}

.sendAllButton {
    margin-top: 10px !important;
    float: right;
    height: 60px;
    width: 180px
}